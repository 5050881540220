/* Light 300 */
@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/gilroy/Gilroy-Light.ttf');
  src: url('/fonts/gilroy/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/gilroy/Gilroy-LightItalic.ttf');
  src: url('/fonts/gilroy/Gilroy-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

/* Book 400 */
@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/gilroy/Gilroy-Regular.ttf');
  src: url('/fonts/gilroy/Gilroy-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/gilroy/Gilroy-RegularItalic.ttf');
  src: url('/fonts/gilroy/Gilroy-RegularItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

/* Medium 500 */
@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/gilroy/Gilroy-Medium.ttf');
  src: url('/fonts/gilroy/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/gilroy/Gilroy-MediumItalic.ttf');
  src: url('/fonts/gilroy/Gilroy-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

/* Bold 700 */
@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/gilroy/Gilroy-Bold.ttf');
  src: url('/fonts/gilroy/Gilroy-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/gilroy/Gilroy-BoldItalic.ttf');
  src: url('/fonts/gilroy/Gilroy-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

/* Heavy 800 */
@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/gilroy/Gilroy-Heavy.ttf');
  src: url('/fonts/gilroy/Gilroy-Heavy.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/gilroy/Gilroy-HeavyItalic.ttf');
  src: url('/fonts/gilroy/Gilroy-HeavyItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

/* Black 850 */
@font-face {
  font-family: 'Gilroy';
  src: url('/fonts/gilroy/Gilroy-Black.ttf');
  src: url('/fonts/gilroy/Gilroy-Black.ttf') format('truetype');
  font-weight: 850;
  font-style: normal;
}

body {
  font-family: 'Inter', sans-serif;
  background-color: white;
}

.freeze-scroll {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Gilroy', serif;
  font-weight: 500;
}

h1 {
  line-height: 5rem;
  letter-spacing: -0.04375rem;
}

strong {
  font-weight: 500;
}

@property --r {
  syntax: '<angle>';
  inherits: false;
  initial-value: 0deg;
}

.rotating-background-button {
  position: absolute;
  inset: -2px;
  z-index: -1;
  background: conic-gradient(from var(--r), var(--colors-esa-secondary) 0%, #eee 10%, var(--colors-esa-secondary) 20%);
  animation: rotating 3s linear infinite;
}

.button-md {
  border-radius: 24px;
}

@keyframes rotating {
  0% {
    --r: 0deg;
  }
  100% {
    --r: 360deg;
  }
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

.markdown h1 {
  font-size: 1.5rem;
  font-weight: 600;
}

.markdown h2 {
  font-size: 1.25rem;
  font-weight: 600;
}

.markdown h3 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.markdown p {
  margin-bottom: 1rem;
}

@media print {
  /* Hide everything */
  body * {
    visibility: hidden;
  }

  .markdown-print-wrapper {
    position: static !important;
  }

  /* Show the element with the class 'markdown' and its children */
  .markdown,
  .markdown * {
    visibility: visible;
  }

  /* Position the element with the class 'markdown' at the top left of the page */
  .markdown {
    position: absolute;
    inset: 0;
  }
}

.hidden-scroll {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hidden-scroll::-webkit-scrollbar {
  display: none;
}

#bbblink img {
  border: 0;
  object-fit: cover;
  width: 150px;
  height: 68px;
  object-position: left;
  /* transition: object-position 100ms; */
}


#bbblink img:hover {
  object-position: right;
}